import { useEffect, useRef } from 'react';
import Image from 'next/image';
import { Col, Grid, Flex, Row } from 'antd';
import { useInView } from 'framer-motion';

import type { HomePageProps } from 'containers/Home';

import AnimatedTitle from 'components/AnimatedTitle';

import { useLogoState } from 'context/LogoContext';

import {
  LogosBackground,
  LogosContainer,
  LogosSubtitle,
  LogosLogo,
  LogosSupergraphic,
} from './styles';

export interface HomePageLogosProps {
  copy: any;
  coInvestors: HomePageProps['coInvestors'];
}

export default function HomePageLogos({
  copy,
  coInvestors,
}: HomePageLogosProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, {
    amount: screens.sm === false ? 0.1 : 0.5,
  });

  const { setIsDarkBackground } = useLogoState();

  useEffect(() => {
    if (isInView) {
      setIsDarkBackground(false);
    }
  }, [isInView, setIsDarkBackground]);

  const isMobile = screens.md === false;

  return (
    <LogosBackground $isMobile={isMobile}>
      {/* <LogosSupergraphic $isMobile={isMobile} $isInView={isInView} /> */}
      <LogosContainer
        align="middle"
        ref={containerRef}
        $isInView={isInView}
        $isMobile={isMobile}
      >
        <Col span={24}>
          <AnimatedTitle
            text={copy.title}
            level={screens.md ? 1 : 3}
            isInView={isInView}
            once={false}
            style={{
              textAlign: 'center',
              margin: '0 auto 20px',
            }}
          />
          <LogosSubtitle $isInView={isInView} $isMobile={isMobile}>
            {copy.subtitle}
          </LogosSubtitle>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 22, push: 1 }}
          lg={{ span: 22, push: 1 }}
          xl={{ span: 18, push: 3 }}
          xxl={{ span: 18, push: 3 }}
        >
          <Row
            gutter={isMobile ? [50, 40] : [100, 60]}
            align="middle"
            justify="center"
          >
            {coInvestors?.map(({ logo }, index) => (
              <Col xs={8} sm={6} md={6} lg={5} xl={5} xxl={5} key={logo.url}>
                <LogosLogo
                  key={logo.url}
                  src={logo.url}
                  alt={logo.name}
                  width={logo.width}
                  height={logo.height}
                  $isMobile={isMobile}
                  $isInView={isInView}
                  $delay={0.7 + index * 0.1}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </LogosContainer>
    </LogosBackground>
  );
}
