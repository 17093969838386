import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Row } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile?: boolean;
  $isInView?: boolean;
  $delay?: number;
}

export const LogosBackground = styled.div<CommonProps>`
  &&& {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LogosSupergraphic = styled.div<CommonProps>`
  &&& {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/supergraphic-onboarding.svg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};
    transition-delay: 1s;
  }
`;

export const LogosContainer = styled(Row)<CommonProps>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '120px 30px 200px' : '80px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
    height: 100%;
    align-content: center;

    @media (min-width: ${theme.breakpoints.md}) and (max-height: 850px) {
      padding-top: 35px;
    }
  }
`;

export const LogosSubtitle = styled(Typography.Paragraph)<CommonProps>`
  &&& {
    margin: 0 auto;
    max-width: 400px;
    color: ${theme.colors.bodyText};
    font-size: ${(props) => (props.$isMobile ? '16px' : '18px')};
    margin-bottom: ${(props) => (props.$isMobile ? '30px' : '70px')};
    text-align: center;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(20px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-delay: 0.3s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};
  }
`;

export const LogosLogo = styled(Image)<CommonProps>`
  &&& {
    width: 100%;
    height: auto;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(40px)'};
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuart')};
    transition-delay: ${(props) => props.$delay}s;
  }
`;
