import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { Grid } from 'antd';
import { useInView } from 'framer-motion';
// import dynamic from 'next/dynamic';
import Link from 'next/link';

// const ThreeIconScene = dynamic(() => import('./ThreeIconScene'), {
//   ssr: false,
// });

import WaitlistModal from 'components/WaitlistModal';
import Strike from './Strike';

import { useLogoState } from 'context/LogoContext';
import { useLoader } from 'context/LoaderContext';

import {
  HeroBackground,
  Hero,
  HeroFooter,
  ScrollIndicator,
  ScrollIndicatorIcon,
  HeroImage,
  HeroTitle,
  HeroSubtitle,
  HeroRegs,
  HeroCta,
} from './styles';

import iconBackgroudImage from 'assets/images/home/hero-background-icon.webp';
import posthog from 'posthog-js';

export interface HomePageHeroProps {
  copy: any;
}

export default function HomePageHero({ copy }) {
  const screens = Grid.useBreakpoint();
  const heroRef = useRef(null);
  const isInView = useInView(heroRef, { amount: 0.5 });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);

  const { setIsDarkBackground } = useLogoState();
  const { isLoaderComplete } = useLoader();

  useEffect(() => {
    if (isInView) {
      setIsDarkBackground(true);
    }
  }, [isInView, setIsDarkBackground]);

  const handleScroll = useCallback(() => {
    posthog.capture('homepage_hero_scroll');
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { once: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const isMobile = screens.md === false;

  return (
    <>
      <WaitlistModal
        isOpen={waitlistModalOpen}
        setIsOpen={setWaitlistModalOpen}
        onComplete={() => {}}
      />
      <HeroBackground>
        <Hero
          $isMobile={isMobile}
          align="middle"
          ref={heroRef}
          $isInView={isInView && isLoaderComplete}
        >
          {/* <ThreeIconScene /> */}
          <HeroImage
            $isMobile={isMobile}
            $isInView={isInView && isLoaderComplete}
            src={iconBackgroudImage}
            alt=""
            width={2400}
            height={2090}
            priority={true}
          />
          <HeroTitle
            $isMobile={isMobile}
            $isInView={isInView && isLoaderComplete}
            level={1}
          >
            <span>Private</span>
            <span>Wealth</span>
            {/* <HeroStrike
              $isMobile={isMobile}
              $isInView={isInView && isLoaderComplete}
              src="/images/strike.svg"
              alt="Strike thorugh"
              width={488}
              height={79}
            /> */}
            <Strike
              isInView={isInView && isLoaderComplete}
              isMobile={isMobile}
            />
          </HeroTitle>
          <HeroFooter
            size={isMobile ? 15 : 30}
            direction="vertical"
            $isMobile={isMobile}
          >
            <HeroSubtitle
              $isMobile={isMobile}
              $isInView={isInView && isLoaderComplete}
            >
              {copy.subtitle}
            </HeroSubtitle>
            <HeroRegs
              $isInView={isInView && isLoaderComplete}
              $isMobile={isMobile}
              onClick={() => setWaitlistModalOpen(true)}
            >
              {copy.regs}
            </HeroRegs>
            {/* <Link href="/insights/what-is-shuttle" passHref legacyBehavior>
              <HeroCta
                $isInView={isInView && isLoaderComplete}
                $isMobile={isMobile}
                onClick={() => {
                  setWaitlistModalOpen(true);
                  posthog.capture('homepage_hero_cta_click');
                }}
              >
                {copy.cta}
              </HeroCta>
            </Link>
            <ScrollIndicator
              $scrollHappened={scrollHappened}
              $isMobile={isMobile}
              $isInView={isInView && isLoaderComplete}
            >
              Scroll
              <ScrollIndicatorIcon />
            </ScrollIndicator> */}
          </HeroFooter>
        </Hero>
      </HeroBackground>
    </>
  );
}
