import * as R from 'ramda';
import { subDays, isBefore, parseISO } from 'date-fns';
import type { Session } from 'next-auth';
import { signOut } from 'next-auth/react';

import { UserProps } from 'types/user';

import { fetchGetJSON } from 'utils/apiHelpers';

const twoFactorExpired = (lastAuthenticated) => {
  if (!lastAuthenticated) {
    return true;
  }

  const thirtyDaysAgo = subDays(new Date(), 30);
  return isBefore(parseISO(lastAuthenticated), thirtyDaysAgo);
};

export const redirectIfUnauthorised = async (
  session: Session,
  origin: string
) => {
  const query = `?origin=${origin}`;

  if (R.isNil(session)) {
    return {
      redirect: {
        destination: `/auth/login${origin ? query : ''}`,
        permanent: false,
      },
    };
  }

  let userData: UserProps;
  try {
    userData = await fetchGetJSON(
      `${process.env.BACKEND_URL}/api/users/me?populate[0]=twoFactorAuth&populate[1]=role`,
      session.jwt
    );
  } catch (error) {
    console.error('AUTH REDIRECT CHECK - Error fetching user data', error);
    if (session.user.confirmed) {
      return {
        redirect: {
          destination: '/auth/logout',
          permanent: false,
        },
      };
    }
  }

  if (session.user.confirmed && !userData?.id) {
    console.error('AUTH REDIRECT CHECK - Error fetching user data');
    return {
      redirect: {
        destination: '/auth/logout',
        permanent: false,
      },
    };
  }

  if (userData?.confirmed !== true && !origin?.includes('confirm-email')) {
    return {
      redirect: {
        destination: '/auth/confirm-email',
        permanent: false,
      },
    };
  }

  if (R.isNil(userData?.persona) && !origin?.includes('onboarding')) {
    return {
      redirect: {
        destination: '/auth/onboarding',
        permanent: false,
      },
    };
  }

  if (
    userData?.persona &&
    userData?.approved !== true &&
    !origin?.includes('application-received')
  ) {
    return {
      redirect: {
        destination: '/auth/application-received',
        permanent: false,
      },
    };
  }

  if (
    userData?.twoFactorAuth?.enabled &&
    !origin?.includes('2fa') &&
    twoFactorExpired(userData?.twoFactorAuth?.lastAuthenticated)
  ) {
    return {
      redirect: {
        destination: '/auth/2fa',
        permanent: false,
      },
    };
  }

  return null;
};

export const redirectIfAuthorised = (session, origin) => {
  const query = `?origin=${origin}`;

  if (session) {
    return {
      redirect: {
        destination: `/dashboard${origin && origin !== '/' ? query : ''}`,
        permanent: false,
      },
    };
  }

  return null;
};
