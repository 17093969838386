import styled from 'styled-components';

import { PageContainer as PC } from 'styles/components';

interface CommonProps {
  $background?: string;
  $isMobile?: boolean;
}

export const PageContainer = styled(PC)<CommonProps>`
  background: ${(props) => props.$background};
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  transition: background-color 0.7s ease-out;
`;

export const PageSection = styled.div`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
  overflow: hidden;
`;
